* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
%flexset {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.carousel {
  @extend %flexset;
  background-color: rgba(213, 213, 213, 0);
  overflow: scroll;
  height: 250px;
  .wrap-flex {
    &__forecast,
    &__cards-carousel {
      @extend %flexset;
      justify-content: flex-start;
      background-color: transparent;
      overflow-y: hidden;
      height: 100%;
      width: 64.9vw;
    }
    &__cards-carousel {
      width: 64.8vw;
    }
    .item {
      @extend %flexset;
      background-color: azure;
      height: 100%;
      flex: 0 0 200px;
      padding: 10px;
      margin-left: 10px;
    }
  }
  button {
    position: sticky;
    transition: all 0.5s;
    z-index: -2;
    opacity: 0;
    outline: none;
    border: none;
    background-repeat: no-repeat;
    background-color: #6166f7;
    border-radius: 0.3rem;
    box-shadow: rgba(0, 0, 0, 0.235) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.187) 0px 4px 6px -2px;
    margin-left: -14px;
  }
  .right {
    right: 0;
  }
  .left {
    &__cards-carousel {
      left: 0;
      margin-left: -34px;
    }
  }
  &__cards-carousel {
    &__style {
      height: 135px !important;
    }
  }
}
.baseleft {
  position: sticky;
  height: 100%;
  left: 0px;
  background-color: #ffffff96;
  top: 0;
  z-index: -4;
  opacity: 0;
  margin-left: -100px;
  width: 60px;
  transition: all 0.5s;
}
.baseright {
  transition: all 0.5s;
  position: sticky;
  height: 100%;
  background-color: #ffffff96;

  right: 0px;
  top: 0;
  width: 80px;
  z-index: -4;
  opacity: 0;
}
