.background {
  z-index: -10;
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 1fr 3fr;
  position: fixed;
  background-color: aqua;
  &__left {
    width: 25vw;
    height: 100%;
    background-color: #c9cfeb;
  }
  &__right {
    width: 75vw;
    height: 100%;
    background-color: #e4e7f3;
    display: grid;
    grid-template-areas:
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . . . . . . . s";
    .square {
      grid-area: s;

      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

      background-color: transparent;
      width: 200px;
      height: 200px;

      &__dotted {
        background-color: #c0c7e1;
        border-radius: 100%;
        width: 6px;
        height: 6px;
        margin: 9px;
      }
    }
  }
}
.main {
  background-color: transparent;
  z-index: -5;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 100;
  top: -3vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  &__square {
    @media only screen and (max-width: 768px) {
      position: relative;
      // top: -30px;
    }
    width: 80vw;
    height: 80vh;
    background-color: white;
    border-radius: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 4.3fr;
    &__left {
      background-color: #6166f7;
      width: 100%;
      height: 100%;
      border-radius: 1.5rem 0px 0px 1.5rem;
    }
    &__rigth {
      &__index {
        overflow: scroll;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 0 1.5rem 1.5rem 0;
        display: grid;
        grid-template-rows: 75px 310px 2fr 2fr;
      }
      &__settings {
        overflow: scroll;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 0 1.5rem 1.5rem 0;
        // display: grid;
        // justify-content: center;
        // align-items: center;
      }
    }
  }
}
.sidebar {
  display: flex;
  justify-content: center;
  transition: all 0.3s;
  .burger {
    background-color: transparent;
    border: none;
    border-radius: 30px 0 0 0;
    width: 4rem;
    height: 4rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    z-index: 200;
    position: fixed;
    cursor: pointer;
    div {
      margin-top: 8px;
      width: 35px;
      height: 3px;
      background-color: #f3f6fb;
      transform-origin: 0;
    }
    @media only screen and (min-width: 920px) {
      display: none;
    }
  }
  .mobile-menu,
  .mobile-block {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    opacity: 0;
    width: 5vw;
    height: 80vh;
    position: fixed;
    left: 10vw;
    border-radius: 1.5rem 0px 0px 1.5rem;
    background-color: #6166f7;
    // background-color: tomato;
    @media only screen and (min-width: 920px) {
      display: none;
    }
  }
  .mobile-block {
    opacity: 0;
    z-index: 10;
    @media only screen and (min-width: 920px) {
      display: none;
    }
  }
}
.sidebar-desk {
  display: none;
}
@media only screen and (min-width: 920px) {
  .sidebar-desk {
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;

    .navbar {
      height: 50px;
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      margin-top: 30px;
      &__settings {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        cursor: pointer;
        border-left: 3px solid transparent;
        outline: none;
        height: 50px;
        margin-bottom: 20px;
        &__p {
          margin-left: 8%;
          font-size: 1rem;
          font-weight: bold;
          color: #fbfbff;
        }
        svg {
          margin-left: 15%;
        }
        path {
          color: #c0c1fc;
          transition: all 0.3s;
          &:focus {
            color: #fff;
          }
        }
      }
    }
  }
}
.logo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #5a5ef5;
  padding: 25px;

  &__icon {
    width: 19px;
    margin-right: 5px;
  }
  &__txt {
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    @media only screen and (max-width: 300px) {
      font-size: 0.6rem;
    }
  }
}

.mobile-menu {
  display: flex;
  flex-flow: column nowrap;
  &__wrap {
    width: 100%;
    height: 90%;
    overflow: scroll;
    position: relative;
    top: 4vh;
  }
  .navbar__settings {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    p {
      color: #ffffff;
      font-weight: bold;
      @media only screen and (max-width: 300px) {
        font-size: 0.8rem;
      }
    }
    path {
      color: #c0c1fc;
    }
  }
}

.sidebar .mobile-menu,
.sidebar .mobile-block {
  z-index: 100;
}

//!

// @supports (-webkit-appearance: none) or (-moz-appearance: none) {
//   input[type="radio"] {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//   }
// }

// @supports (-webkit-appearance: none) or (-moz-appearance: none) {
//   input[type="radio"] {
//     --active: #275efe;
//     --active-inner: #fff;
//     --focus: 1px rgba(39, 94, 254, 0.25);
//     --border: #bbc1e1;
//     --border-hover: #275efe;
//     --background: #fff;
//     --disabled: #f6f8ff;
//     --disabled-inner: #e1e6f9;
//   }
// }

// input[type="radio"] {
//   --active: #275efe;
//   --border: #bbc1e190;
//   border: 3px solid var(--bc, var(--border));
// }

// input[type="radio"]:checked {
//   --b: var(--active);
//   --bc: var(--active);
// }

// input[type="radio"]:not(:checked):not(:disabled):hover {
//   --bc: var(--border-hover);
// }

// input[type="radio"] {
//   --focus: 2px rgba(39, 94, 254, 0.25);
//   outline: none;
//   transition: all 0.3s;
// }

// input[type="radio"]:focus {
//   box-shadow: 0 0 0 var(--focus);
// }

// input[type="radio"] + label {
//   display: inline-block;
//   vertical-align: top;
// }

// input[type="radio"]:disabled + label {
//   cursor: not-allowed;
// }

//!
.systemPattern {
  width: 65vw;
  &__values {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    &__default {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      background-color: #fcfcfc;
      border: 1px solid #f4f4f4;
      padding: 2.5%;
      border-radius: 0.8rem;
      margin-left: 3%;
    }
    &__label {
      cursor: pointer;
    }
    &__radio {
      cursor: pointer;
      height: 10px;
      width: 10px;
      border-radius: 100%;
    }
  }
}
