%flexCenter {
  display: flex;
  justify-content: center;
  align-content: center;
}
.form {
  margin-top: 20px;
  margin-left: 2%;
  display: flex;
  flex-flow: row nowrap;
  &__search {
    transition: all 0.3s ease-in-out;
    background-color: #f3f6fb;
    outline: none;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 0.8rem;
    margin-right: 10px;
    background-image: url("../../img/icons/searchIcon.svg");
    background-repeat: no-repeat;
    background-position: center;
    caret-color: #6166f7;
    cursor: pointer;

    &:hover {
      z-index: 10;
      background-color: #dce9ff;
    }
  }
  &__input {
    height: 40px;
    width: 100%;
    margin-right: 2%;
    border: none;
    outline: none;
    border-bottom: 1px solid #96a3b129;
    color: #6166f7;
    transition: all 0.3s;
    &::placeholder {
      color: #c6cbd4;
    }
    &:focus {
      border-bottom: 1px solid #6166f7;
    }
  }
}
.ex-forecast {
  &__title {
    margin: 0 0 3% 3% !important;
  }
}
.my-location {
  display: flex;
  background-color: #fff;
  border: 2px solid #f1eded;
  padding: 8px;
  border-radius: 0.6rem;
  color: #6a7682;
  cursor: pointer;
  transition: all 0.3s;

  width: 70%;
  margin-left: -10%;

  justify-self: center;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  outline: none;
  @media screen and (max-width: 540px) {
    font-size: 0.4rem;
    @media screen and (max-width: 280px) {
      font-size: 4px;
    }
  }
  &:hover {
    border: 2px solid #c3bfbf;
  }
}
.main__square__right__search {
  display: grid;
  grid-template-columns: 80% 20%;
  align-items: center;
  justify-content: center;
}
.sidebar-desk__menu {
  overflow: scroll;
  height: 86vh;
}
.side-bar-card {
  margin-top: 26vh;
  width: 100%;
  height: 365px;
  background: transparent;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  @media screen and (max-width: 920px) {
    margin-top: 10vh;
  }
  &__square {
    background-color: #fff;
    width: 75%;
    border-radius: 1rem;
    padding: 10%;
    margin-bottom: 10px;
    @media screen and (min-width: 920px) {
      padding: 20px;
    }
    &__foot {
      margin-top: 10px;
      &__wind {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: 0.8rem;
        flex-flow: wrap;
        &__txt {
          font-weight: 600;
          font-size: 0.7rem;
          @media screen and (max-width: 920px) {
            margin-top: -5px;
            font-size: 0.5rem;
          }
        }
      }
      &__percent {
        font-size: 0.8rem;
        &__preci {
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
          @media screen and (max-width: 360px) {
            font-size: 0.6rem;
          }
        }
        &__humi {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-flow: wrap;
          @media screen and (max-width: 360px) {
            font-size: 0.6rem;
          }
          &__txt {
            font-weight: 600;
            font-size: 0.7rem;
          }
        }
      }
    }
    &__middle {
      @extend %flexCenter;
      flex-flow: column;
      margin-top: 30px;

      &__location {
        @extend %flexCenter;
        flex-flow: column;
        text-align: center;
        h2 {
          font-weight: 600;
          margin: 0;
          font-size: 1.2rem;
          @media screen and (max-width: 360px) {
            font-size: 1rem;
          }
        }
        &__country {
          font-size: 0.8rem;
          @media screen and (max-width: 360px) {
            font-size: 0.6rem;
          }
        }
      }
      &__temp {
        @extend %flexCenter;
        font-size: 2rem;
        margin-bottom: 3px;
        @media screen and (max-width: 360px) {
          font-size: 1.4rem;
        }
      }
    }
    &__top {
      display: flex;
      justify-content: center;
      align-items: center;
      &__date {
        &__today {
          font-size: 1.18rem;
          font-weight: 600;
          @media screen and (max-width: 280px) {
            font-size: 0.8rem;
          }
        }
        &__hour {
          margin-top: 6px;
          h5 {
            font-weight: 600;
          }
          p {
            font-size: 0.9rem;
            color: #707c92;
          }
        }
      }
      img {
        width: 25px;
        margin-right: 15px;
        margin-top: -20px;
      }
    }
  }
}
.bar {
  width: 100%;
  height: 3px;
  margin-bottom: 10%;
  background-color: #dbdff4;
}
.speedometer-general {
  padding-top: 13px;
}
