.card-square {
  background-color: #fcfcfc;
  border: 1px solid #f4f4f4;
  border-radius: 1.5rem;
  flex: 0 0 80px;
  height: 112px;
  margin-left: 30px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 0.8rem;
  justify-content: space-evenly;
  margin-top: -10px;
  &__icon {
    width: 25px;
    height: 25px;
  }
  &__temp {
    margin-top: -7px;
  }
  &__day {
    margin-top: -10px;
    color: #718295;
  }
}
